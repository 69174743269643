<template>
  <div class="page-content">
    <page-breadcrumb title="Exams" class="mb-2" />

    <data-create-popup title="Create Group"
      :schema="create_schema" :default-data="{title: '', type: 1, slug: ''}"
      :create-data-fn="createItem" @create="$refs.group_list.getList()"
    />
    <data-table-ssr id="group_list" ref="group_list"
      :columns="fields" :get-list-fn="getList"
      :to-edit-fn="toEditPage"
    />
  </div>
</template>

<script>
import service from '../service'
import { TYPE_OPTIONS } from '../constants';

const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Title', field: 'title', validate: { required: true } },
      { label: 'Type', field: 'type', input_type: 'select', options: TYPE_OPTIONS },
      { label: 'Slug', field: 'slug', validate: { required: true }}
    ]
  }
];

const fields = [
  { label: 'Title', field: 'title' },
  { label: 'Type', field: 'type', input_type: 'select', options: TYPE_OPTIONS },
  { label: 'Slug', field: 'slug', validate: { required: true }}
];

export default {
  data(){
    return{
      create_schema,
      fields,
      total: 0,
    }
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    async getList({ limit, page, query }) {
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'goga-exam-detail', params: { id: _id } });
    },
  }
}
</script>
